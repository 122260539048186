/* inter fonts family */

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

/* Poppins fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600?family=Roboto+Serif:opsz,wght@8..144,100;8..144,200;8..144,300;8..144,400;8..144,500;8..144,600;8..144,700;8..144,800&display=swap');

@tailwind base;
@tailwind components;
.loader {
  border: 2px solid #f3f3f3;
  @apply border-t-2 border-t-gray-300;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@tailwind utilities;

@font-face {
  font-family: 'filsonPro-light';
  src: url('./static/fonts/FilsonProLight.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'filsonPro-regular';
  src: url('./static/fonts/FilsonProRegular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'filsonPro-book';
  src: url('./static/fonts/FilsonProBook.otf') format('opentype');
  font-weight: normal;
  font-style: no rmal;
}
@font-face {
  font-family: 'filsonPro-semibold';
  src: url('./static/fonts/FilsonProMedium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'filsonPro-bold';
  src:
    local('filsonPro-bold'),
    url('./static/fonts/FilsonProBold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  overflow: auto;
  height: 100%;
}

/* To change scrollbar style */
::-webkit-scrollbar-thumb {
  width: 10px;
  height: 10px;
  border-right: 1px solid rgb(70, 67, 67);
  background-color: rgb(95, 95, 95);
}

::-webkit-scrollbar {
  width: 4px;
  height: 7px;
  background-color: transparent;
}

/* To resolve sidebar scroll issue on  iphone*/
.custom-scrollbar {
  -webkit-overflow-scrolling: touch;
}

.full-wrapper {
  background: url('../src/static/images/bg_top.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

@media only screen and (max-width: 1024px) {
  body {
    font-size: 14px;
  }
}

.modal-enter {
  opacity: 0;
  top: 48% !important;
  /* transform: translateY(-45%); */
}

.modal-enter-active {
  opacity: 1;
  top: 50%;
  /* transform: translateY(-50%); */
}

.modal-exit {
  top: 50%;
  opacity: 0.8;
}
.modal-exit-active {
  top: 45%;
  opacity: 0;
}

.dropdown-enter {
  animation: dropdown-enter-anim 500ms ease-in-out;
}

.dropdown-enter-active {
  transform: translateY(0%);
}
.dropdown-exit {
  transform: translateY(0%);
  opacity: 1;
}
.dropdown-exit-active {
  transform: translateY(-100%);
  opacity: 0;
  transition: all 400ms;
}

.custom-paragraph {
  margin-bottom: 1rem; /* Adjust the spacing as needed */
}

.react-markdown-container p:last-child.custom-paragraph {
  margin-bottom: 0; /* Remove the bottom margin for the last paragraph */
}

@keyframes dropdown-enter-anim {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  50% {
    transform: translateY(0%);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.animate-bounce1 {
  animation: bounce 1s infinite ease-in-out;
  animation-delay: 0.1s;
}

.animate-bounce2 {
  animation: bounce 1s infinite ease-in-out;
  animation-delay: 0.25s;
}

.animate-bounce3 {
  animation: bounce 1s infinite ease-in-out;
  animation-delay: 0.35s;
}

@keyframes bounce {
  0%,
  40%,
  70%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(0px);
  }
}

/* Shepherd.js */

.shepherd-header {
  background-color: #fe6629 !important;
  z-index: 30;
}

.shepherd-header #intro-label,
#second-step-label,
#fourth-step-label,
#fifth-step-label {
  text-wrap: wrap;
  color: #fe6629;
  text-wrap: wrap;
}

/* max-width: 542px; */

.shepherd.max-width-542 {
  max-width: 542px !important;
}
.shepherd.max-widht-440 {
  max-width: 440px !important;
}

.shepherd-title {
  font-family: 'Inter', sans-serif;
  color: #ffffff !important;
  font-size: 1.3rem;
}

.first-item .shepherd-arrow::before {
  display: none !important;
}

.bottom-arrow .shepherd-arrow::before {
  background-color: white !important;
  z-index: 2000 !important;
}

.shepherd-cancel-icon {
  cursor: pointer;
  margin-left: 0.5rem;
  transform: translate(0.4rem, -1rem);
  color: white !important;
}
.shepherd-cancel-icon:hover {
  color: #ad280e !important;
}

.shepherd-text {
  font-family: 'Inter', sans-serif;
  font-size: 17px;
  margin-bottom: 10px;
  color: white !important;
}

.shepherd-footer {
  justify-content: center;
}

.shepherd-cancel-icon {
  cursor: pointer;
  margin-left: 0.5rem;
  transform: translate(0.4rem, -1rem);
  color: #ffffff; /* Set the color to white */
}
.shepherd-cancel-icon:hover {
  color: #ad280e !important;
}

.shepherd-button {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  margin: 7px 10px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #fe6629;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.3s;
}

.radio-button-group label {
  color: black; /* Ensures the text color is black */
}

.shepherd-button:hover {
  background-color: #ad280e !important;
  color: rgb(255, 255, 255);
}

.highlight {
  border: 2px solid yellow !important;
}

@media only screen and (max-width: 640px) {
  .shepherd-has-title .shepherd-content .shepherd-header {
    padding: 0.5rem !important;
  }
  .shepherd-title {
    font-size: 1.125rem;
    width: 80%;
  }
  .shepherd-cancel-icon {
    transform: translate(0.1rem, -0.5rem);
  }
  .shepherd-text {
    font-size: 16px;
  }
  .shepherd-button {
    font-size: 14px;
  }
  .shepherd.shepherd-element,
  .shepherd.shepherd-element {
    max-width: 300px !important;
  }
  .shepherd.sm-top,
  .shepherd.sm-left {
    left: 60px !important;
    top: 24px !important;
  }
  .shepherd-button {
    margin: 7px;
    padding: 7px 12px;
    font-size: 14px;
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 50px;
  color: #fff;
  text-align: start;
  border-radius: 6px;
  font-size: 12px;
  word-wrap: break-word;
  white-space: wrap;
  padding: 5px 10px;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.expandable-text {
  display: inline-block;
  max-width: var(--text-length, 100px); /* Default max-width */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: max-width 0.3s ease-in-out;
}

.expandable-text.expand {
  display: inline;
  white-space: normal;
  overflow: visible;
  max-width: none;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: 1.5em;
  max-height: 4.5em; /* 3 lines * line-height */
}

.spin {
  animation: spin 2s linear infinite; /* Name of animation, duration, timing function, and iteration count */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Chat Css */

.animate-bounce1 {
  animation: bounce 1s infinite ease-in-out;
  animation-delay: 0.1s;
}

.animate-bounce2 {
  animation: bounce 1s infinite ease-in-out;
  animation-delay: 0.25s;
}

.animate-bounce3 {
  animation: bounce 1s infinite ease-in-out;
  animation-delay: 0.35s;
}

@keyframes bounce {
  0%,
  40%,
  70%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(0px);
  }
}

.react-markdown-container h1 {
  font-size: 1.75em;
}

.react-markdown-container h2 {
  font-size: 1.5em;
}

.react-markdown-container h3 {
  font-size: 1.25em;
}

.react-markdown-container h4 {
  font-size: 1em;
}

.react-markdown-container h5 {
  font-size: 1em;
}

.react-markdown-container h6 {
  font-size: 0.875em;
}

.react-markdown-container h3 {
  margin: 1rem 0px 0.5rem;
}

.react-markdown-container ul li {
  margin: 1.25rem 0px 0.5rem;
}

.react-markdown-container ul,
ol li ul,
ol {
  margin-bottom: 0.75rem;
  padding-left: 1.625rem;
}

.react-markdown-container ul li ul li {
  margin: 0.5rem 0rem;
  padding-left: 0.375rem;
}

.prose :where(ol > li):not(:where([class~='not-prose'] *))::marker {
  color: #ececec;
  font-weight: 400;
}

.react-markdown-container ul li ul li::marker {
  color: #ececec;
}

.react-markdown-container ul li ul li {
  list-style-type: disc;
  margin-bottom: 1.25em;
  margin-top: 1.25em;
}
