.base {
  background: linear-gradient(253.98deg, #e04c11 22.31%, #fe6629 77.69%);
}

.stripes {
  background-image: linear-gradient(
    45deg,
    #fe6629 25%,
    transparent 25%,
    transparent 50%,
    #fe6629 50%,
    #fe6629 75%,
    transparent 75%,
    transparent
  );

  background-size: 20px 20px;
  animation: move-stripes 1s linear infinite;
}

@keyframes move-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}
